import { Fragment, ReactNode } from 'react';
import { Regions } from 'core/user/entities';

interface LocationRegionOptionProps {
  option: Regions | Regions[];
  children: ReactNode;
}

export const LocationRegionOption = ({ children, option }: LocationRegionOptionProps) => (
  <Fragment key={String(option)}>{children}</Fragment>
);
