import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import RenderOnlyOnClient from 'core/common/components/RenderOnlyOnClient';
import { BASE_ROUTES } from 'core/common/constants';
import { useCookieConsent } from 'core/common/hooks';
import { ColorVariants } from 'core/theme/entities';
import { europeanUnionCountries, Locales, Regions } from 'core/user/entities';
import { Location, LocationOption, LocationRegion, LocationRegionOption } from 'core/user/ui';
import { BurgerIcon, Button, CloseButtonStyled, Container, NavLink } from './styled';

type HeaderMenuProps = {
  variant?: ColorVariants;
};

export const HeaderMenu = ({ variant = 'primary' }: HeaderMenuProps) => {
  const { toggleReconsent } = useCookieConsent();

  const [isOpened, setIsOpened] = useState(false);

  const onControlClick = () => {
    setIsOpened((prev) => !prev);
  };

  const onClose = () => setIsOpened(false);

  return (
    <>
      <BurgerIcon onClick={onControlClick} variant={variant} />
      <Drawer open={isOpened} onClose={onClose}>
        <Container>
          <CloseButtonStyled onClick={onClose} />
          <nav>
            <NavLink href={BASE_ROUTES.TECH_PRIVACY_POLICY}>Privacy policy</NavLink>
            <NavLink href={BASE_ROUTES.TECH_TERMS}>Terms of use</NavLink>
            <NavLink href={BASE_ROUTES.TECH_PAYMENT_TERMS}>Payment terms</NavLink>
            <NavLink href={BASE_ROUTES.TECH_SUBSCRIPTION_TERMS}>Subscription terms</NavLink>
            <NavLink href={BASE_ROUTES.TECH_MONEY_BACK_POLICY}>Money back policy</NavLink>
            <RenderOnlyOnClient>
              <Location>
                <LocationOption option={[...europeanUnionCountries, Locales.GB]}>
                  <Button onClick={toggleReconsent}>Consent settings</Button>
                </LocationOption>
                <LocationOption option={Locales.OTHER}>{null}</LocationOption>
              </Location>
            </RenderOnlyOnClient>
            {/*<NavLink href={BASE_ROUTES.TECH_DELIVERY_POLICY}>Delivery & Return Policy</NavLink>*/}
            <LocationRegion>
              <LocationRegionOption option={Regions.CALIFORNIA}>
                <NavLink href={BASE_ROUTES.TECH_PERSONAL_INFO_POLICY}>Personal information</NavLink>
              </LocationRegionOption>
            </LocationRegion>
            <NavLink href={BASE_ROUTES.TECH_FAQ}>FAQ</NavLink>
            <NavLink href={BASE_ROUTES.TECH_CONTACT_US}>Contact us</NavLink>
          </nav>
        </Container>
      </Drawer>
    </>
  );
};

export default HeaderMenu;
