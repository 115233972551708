import styled, { keyframes } from 'styled-components';
import { ColorVariants, ThemeTypes } from 'core/theme/entities';
import CircularProgress from '../CircularProgress';

export const ButtonLoadingIndicator = styled(CircularProgress)``;

export const ButtonStyled = styled.button<{
  variant?: ColorVariants;
  disabled?: boolean;
  fullWidth?: boolean;
}>`
  z-index: 2;
  display: inline-flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: ${(props) => (props.fullWidth ? '100%' : '330px')};
  max-width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: ${(props) =>
    props.variant === 'primary-outlined' || props.variant === 'secondary-outlined'
      ? `1px solid ${props.theme.colors.primary600}`
      : 'none'};
  border-radius: 12px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  color: ${(props) =>
    ((props.variant === 'secondary' || props.variant === 'secondary-outlined') &&
      props.theme.colors.info) ||
    (props.variant === 'primary-outlined' && props.theme.colors.primary600) ||
    props.theme.colors.typography100};
  background: ${(props) =>
    ((props.variant === 'secondary' || props.variant === 'secondary-outlined') &&
      props.theme.colors.typography100) ||
    (props.variant === 'success' && props.theme.colors.success500) ||
    (props.variant === 'primary-outlined' && 'transparent') ||
    (props.theme.name === ThemeTypes.PALMISTRY && props.theme.colors.infoLight) ||
    props.theme.gradients.primary};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => props.disabled && 'not-allowed'};
  user-select: none;
  outline: none;

  @media ${(props) => props.theme.breakpoints.desktop} {
    height: 50px;
  }

  ${ButtonLoadingIndicator} {
    color: ${(props) =>
      ((props.variant === 'secondary' || props.variant === 'secondary-outlined') &&
        props.theme.colors.info) ||
      props.theme.colors.typography100} !important;
  }
`;

export const ButtonLabel = styled.span`
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

export const ButtonIcon = styled.span`
  display: inherit;
  margin-right: 12px;
  margin-left: -6px;
`;

const impulseBtn = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(39, 174, 96,.3), 0 0 0 0 rgba(39, 174, 96,.2)
  } 18% {
    box-shadow: 0 0 0 0 rgba(39, 174, 96,.3), 0 0 0 0 rgba(39, 174, 96,.2)
  } 100% {
    box-shadow:0 0 0 16px rgba(39, 174, 96,0),0 0 0 27px rgba(39, 174, 96,0)
  }
`;

export const StyledImpulseButton = styled(ButtonStyled)`
  position: relative;
  left: 0;
  display: flex;
  border-radius: 26px;
  box-shadow: 0 12px 23px rgba(39, 174, 96, 0.31);
  transition: 0.2s all ease;
  transform: translateX(0);

  ::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 26px;
    animation: ${impulseBtn} 2s ease-out 0s infinite normal none running;
    content: '';
  }
`;

export const impulseScaleBtn = keyframes`
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 4px rgba(153, 116, 246, 0.2);
  }
  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(153, 116, 246, 0.2);
  }
`;

export const StyledImpulseScaleButton = styled(ButtonStyled)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.infoLight};
  box-shadow: 0 0 0 0 rgba(153, 116, 246, 0.5);
  animation: ${impulseScaleBtn} 1.5s infinite;

  &:hover {
    animation: none;
  }
`;

export const TextButtonStyled = styled(ButtonStyled)`
  width: auto;
  height: auto;
  padding: 0;
  border: none;
  background: transparent;
`;
