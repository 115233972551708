import { SVGProps } from 'react';
import sprite from 'core/common/assets/images/sprites/sprite.svg';
import { IconNameOrString } from 'core/svg-sprites/entities';
import { StyledSvg } from './styled';

export type IconProps = {
  name: IconNameOrString;
  title?: string;
  className?: string;
} & Omit<SVGProps<SVGSVGElement>, 'name' | 'ref'>;

const Icon = ({ name, title, className = '', ...rest }: IconProps) => {
  const role = title ? 'img' : undefined;
  const ariaHidden = !title;

  return (
    <StyledSvg
      {...rest}
      aria-hidden={ariaHidden}
      width={rest.width ?? '16px'}
      height={rest.height ?? '16px'}
      role={role}
      className={className}
    >
      {title && <title>{title}</title>}
      <use xlinkHref={`${sprite}#${name}`} />
    </StyledSvg>
  );
};

export default Icon;
