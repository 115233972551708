import styled from 'styled-components';
import { ReactComponent as ButtonBack } from 'core/common/assets/images/back.svg';
import { ColorVariants } from 'core/theme/entities';

export const Back = styled(ButtonBack)<{ variant?: ColorVariants }>`
  position: absolute;
  top: 20px;
  left: 0;
  color: ${(props) =>
    (props.variant === 'secondary' && props.theme.colors.typography100) ||
    props.theme.colors.typography700};
  cursor: pointer;
`;
