import { AnchorHTMLAttributes, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { ExternalLink } from './styled';

export type LinkProps = Omit<NextLinkProps, 'as'> &
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    children?: ReactNode;
    className?: string;
    href: string;
  };

const Link = ({ children, href, target, className = '', ...otherProps }: LinkProps) => {
  return (
    <NextLink href={href}>
      <ExternalLink {...otherProps} target={target} className={className}>
        {children}
      </ExternalLink>
    </NextLink>
  );
};

export default Link;
