import { Fragment, ReactNode } from 'react';
import { Locales } from 'core/user/entities';

interface LocationOptionProps {
  option: Locales | Locales[];
  children: ReactNode;
}

export const LocationOption = ({ children, option }: LocationOptionProps) => (
  <Fragment key={String(option)}>{children}</Fragment>
);
