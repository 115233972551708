import { ButtonHTMLAttributes, ReactNode } from 'react';
import { TextButtonStyled } from './styled';

export type TextButtonProps = {
  className?: string;
  icon?: ReactNode;
  loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const TextButton = ({ className, ...otherProps }: TextButtonProps) => (
  <TextButtonStyled className={className} {...otherProps} />
);

export default TextButton;
