import { CircularProgress as MaterialCircularProgress } from '@mui/material';

export type CircularProgressProps = {
  size?: number | string;
  className?: string;
};

const CircularProgress = (props: CircularProgressProps) => {
  const { size, className } = props;

  return <MaterialCircularProgress className={className} size={size} />;
};

export default CircularProgress;
