import { HTMLAttributes } from 'react';
import { ButtonStyled, CrossIconStyled } from './styled';

type CloseButtonProps = HTMLAttributes<HTMLButtonElement>;

const CloseButton = (props: CloseButtonProps) => {
  return (
    <ButtonStyled {...props}>
      <CrossIconStyled name="cross" />
    </ButtonStyled>
  );
};

export default CloseButton;
