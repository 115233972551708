import styled from 'styled-components';
import { ReactComponent as Burger } from 'core/common/assets/images/burger.svg';
import { TextButton } from 'core/common/components/Button';
import CloseButton, { CrossIconStyled } from 'core/common/components/CloseButton';
import Link from 'core/common/components/Link';
import { ColorVariants } from 'core/theme/entities';

export const BurgerIcon = styled(Burger)<{ variant?: ColorVariants }>`
  position: absolute;
  right: 0;
  color: ${(props) =>
    (props.variant === 'secondary' && props.theme.colors.typography100) ||
    props.theme.colors.typography700};
  cursor: pointer;
`;

export const CloseButtonStyled = styled(CloseButton)`
  margin-bottom: 20px;

  ${CrossIconStyled} {
    width: 24px;
    height: 24px;
  }
`;

export const Container = styled.div`
  width: 312px;
  padding: 20px;
`;

export const NavLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
`;

export const Button = styled(TextButton)`
  display: block;
  margin: 0 0 10px;
  color: inherit;
`;
