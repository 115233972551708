import { ReactElement } from 'react';
import { useLogger } from 'core/common/hooks';
import { Regions } from 'core/user/entities';
import { useUserLocation } from 'core/user/hooks';

interface LocationRegionProps {
  children: ReactElement[] | ReactElement;
}

export const LocationRegion = ({ children }: LocationRegionProps) => {
  const { region } = useUserLocation();
  const logger = useLogger();

  const getUserLocationFromArray = (childrenArray: ReactElement[]): ReactElement | null => {
    if (!childrenArray.some(({ props }) => props.option === Regions.OTHER)) {
      logger.warn(
        'You should create LocationOption component with option="OTHER" as default location',
      );
    }

    const currentLocale = childrenArray.find(
      ({ props }) =>
        !!(
          (typeof props.option === 'string' && props.option === region) ||
          (Array.isArray(props.option) && props.option.some((item: string) => item === region))
        ),
    );
    return (
      currentLocale || childrenArray.find(({ props }) => props.option === Regions.OTHER) || null
    );
  };

  const getCorrectUserLocation = (child: ReactElement) => {
    if (!child.props.option) {
      logger.warn('The Location component should contain one or more LocationOption components');
      return null;
    }
    if (child.props.option === region) return <>{child}</>;
    return null;
  };

  const child = Array.isArray(children)
    ? getUserLocationFromArray(children)
    : getCorrectUserLocation(children);

  return <>{child}</>;
};
